export const fadeInDownShort = {
  0: {
    opacity: 0,
    transform: [{ translateY: -20 }],
  },
  1: {
    opacity: 1,
    transform: [{ translateY: 0 }],
  },
}

export const fadeInUpShort = {
  0: {
    opacity: 0,
    transform: [{ translateY: 20 }],
  },
  1: {
    opacity: 1,
    transform: [{ translateY: 0 }],
  },
}
